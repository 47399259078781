import React, { useEffect } from 'react';
import { ChevronDown } from 'react-feather';
import AOS from 'aos';
import "aos/dist/aos.css";

import Footer from '../shared/Footer';
import smile1 from '../../assets/images/cvcorpSmile.jpg';
import smile2 from '../../assets/images/cvcorpSmile2.jpg';
import smile3 from '../../assets/images/cvcorpSmile4.jpg';
import smile4 from '../../assets/images/cvcorpSmile6.jpg';
import smile5 from '../../assets/images/cvcorpSmile3.jpg';
import smile6 from '../../assets/images/cvcorpSmile5.jpg';
import smile7 from '../../assets/images/cvcorpStudent.jpg';
import './about.css'
import { ImageEle, sharedHeading, miniSharedHeading } from '../shared/SharedElemets';

const About = () => {

    useEffect(() => {
        AOS.init({
            duration: 600,
            offset: -10,
            once: true,
        });
      }, []);

    return (
        <div className="bgColor1 w-full divCenter flex-col">
            {ImageEle}
            <div data-aos="fade-down" className="w-full z-10 divCenter flex-col px-2">
                {miniSharedHeading("Your Career Partner")}
                {sharedHeading("About Us")}
            </div>
            <div data-aos="fade-up" className="text-gray-800 text-center font-light divCenter flex-col py-14">
                <p className="lg:text-4xl text-3xl customFont">Our ideology</p>
                <q className="md:text-2xl text-xl md:w-2/3 mt-10 tracking-wider">Never worry about Placements, Worry about how much you are prepared to grab the given opportunity</q>
                <div className="rounded-full mt-14" style={{ backgroundColor: '#d0202d' }}><ChevronDown color='white' size={30} /></div>
            </div>
            <div className="bgColor2 w-full px-2">
                <p data-aos="fade-up" className="text-center my-10 md:text-2xl text-xl"><span className="text-red-600 lg:text-5xl text-3xl">8</span> Years of Experience in <span className="text-red-600">Training</span> & <span className="text-red-600">Placement</span></p>
            </div>
            <div className="bgColor1 py-6">
                <div data-aos="fade-up" className="h-72 flex overflow-x-scroll">
                    {
                        [smile5, smile1, smile2, smile3, smile4, smile6, smile7].map((x, index) => (
                            <img src={x} key={index} alt={`cvcorp Student ${index}`} className="mx-2 rounded-xl object-contain" />  
                        ))
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About