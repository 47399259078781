import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";

import Footer from '../shared/Footer';
import { ImageEle, miniSharedHeading, sharedHeading } from '../shared/SharedElemets';
import '../about/about.css';
import { ChevronDown } from 'react-feather';
import YChart from './ByYear';

const Placements = () => {

    useEffect(() => {
        AOS.init({
            duration: 600,
            offset: -10,
            once: true,
        });
    }, []);

    return (
        <div className="bgColor1 w-full divCenter flex-col text-center">
            {ImageEle}
            <div data-aos="fade-down" className="w-full z-10 divCenter flex-col px-2">
                {miniSharedHeading("Your Career Partner")}
                {sharedHeading("Placements")}
            </div>
            <div data-aos="fade-up" className='lg:w-1/2 w-full py-14 px-2'>
                <YChart />
            </div>
            <div className="bgColor2 w-full divCenter flex-col px-2 py-10">
                <p data-aos="fade-up" className="text-center my-6 lg:text-2xl text-xl">Till date <span className="text-red-600 font-medium">every Student</span> who joined CVCORP and came regularly got placed without fail.</p>
                <p data-aos="fade-up" className="font-light md:text-xl text-base md:w-2/3 tracking-wider">For a detailed overview of Success Stories, head to our Dashboard</p>
                <div className="animate-bounce w-full text-2xl mt-4 divCenter bottom-20">
                    <ChevronDown size="34" color="white" />
                </div>
                <a data-aos="fade-up" className="px-2 mt-4 w-2/3" href="https://cvcorp-dashboard.web.app/dashboard"><button className="neoButtonDark w-full lg:text-2xl text-xl border border-white">Dashboard</button></a>
            </div>
            <div className="bgColor1 w-full py-10 divCenter flex-col px-2">
                <p data-aos="fade-up" className="lg:text-5xl text-3xl customFont text-red-600" style={{ letterSpacing: '0.05em' }}>Placement in 150+ companies</p>
            </div>
            <Footer />
        </div>
    )
}

export default Placements;