import React from 'react';
import { Instagram, Facebook, Linkedin } from 'react-feather';

const Footer = () => {

    return (
        <div className="divCenter flex-col text-white pt-16 w-full" style={{ backgroundColor: '#d0202d' }}>
            <div className="divCenter w-full flex-col px-4 ">
                <div>
                    <h2 data-aos="fade-up" className="text-center font-normal text-base lg:text-xl" style={{ letterSpacing: '0.5em' }}>Contact Us</h2>
                    <h1 data-aos="fade-up" className="text-center mt-6 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"><a href="tel:7288840444">7288840444</a> / <a href="tel:9705198299">9705198299</a></h1>
                </div>
                <div>
                    <h2 data-aos="fade-up" className="text-center font-normal mt-10 text-base lg:text-xl" style={{ letterSpacing: '0.5em' }}>Social Media Handles</h2>
                    <div data-aos="fade-up" className="text-center font-base md:text-xl lg:text-xl text-base divCenter mt-6">
                        <a href="http://instagram.com/cvcorp"><button className="neoButtonRed divCenter tracking-widest mx-2"><Instagram size={34} /></button></a>
                        <a href="https://www.linkedin.com/company/cvcorp"><button className="neoButtonRed divCenter tracking-widest mx-2"><Linkedin size={34} /></button></a>
                        <a href="https://www.facebook.com/cvcorpin"><button className="neoButtonRed divCenter tracking-widest mx-2"><Facebook size={34} /></button></a>
                    </div>
                </div>
            </div>
            <p className="mb-2 mt-16 text-center bottom-0 font-light text-sm md:w-2/3 tracking-wider mx-2">© Copyright {new Date().getFullYear()} CVCORP, Inc. All rights reserved. Various trademarks held by their respective owners.</p>
        </div>
    )
}

export default Footer;