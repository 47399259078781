import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const DropMenu = () => {

    const currentLocation = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentLocation]);

    const [menu, setMenu] = useState(false);

    return (
        <>
            <div onClick={() => setMenu(!menu)} className="fixed lg:left-8 left-4 top-8 z-50">
                <div className={`h-4 w-6 lg:w-8 ${menu ? 'open' : ''}`} id="menu-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className={`${menu ? 'open' : ''} fixed w-screen md:left-0 bg-black z-40 menu`}>
                <div className={`${menu ? 'open' : ''} divCenter flex-col text-red-600 customFont h-full w-full text-2xl overflow-y-scroll md:text-4xl menuItems`}>
                    <p className="text-white mb-10 tracking-widest underline">Menu</p>
                    <Link to={'/home'} onClick={() => setMenu(false)} className={`link hover:text-red-600 ${window.location.pathname === '/home' ? 'text-red-600' : 'text-white'}`}>Home</Link>
                    <Link to={'/about'} onClick={() => setMenu(false)} className={`link hover:text-red-600 ${window.location.pathname === '/about' ? 'text-red-600' : 'text-white'}`}>About Us</Link>
                    <Link to={'/course'} onClick={() => setMenu(false)} className={`link hover:text-red-600 ${window.location.pathname === '/course' ? 'text-red-600' : 'text-white'}`}>Course</Link>
                    <Link to={'/placements'} onClick={() => setMenu(false)} className={`link hover:text-red-600 ${window.location.pathname === '/placements' ? 'text-red-600' : 'text-white'}`}>Placements</Link>
                    <a href="https://cvcorp-dashboard.web.app/talent-test" className={`link hover:text-red-600 ${window.location.pathname === '/talent-test' ? 'text-red-600' : 'text-white'}`}>Talent Test</a>
                    <Link to={'/contact'} onClick={() => setMenu(false)} className={`link hover:text-red-600 ${window.location.pathname === '/contact' ? 'text-red-600' : 'text-white'}`}>Contact US</Link>
                </div>
            </div>
        </>
    )
}

export default DropMenu;