import lightLogo from '../../assets/logo/cvcorp-logo.png';

export const ImageEle =
    <div className={`divCenter absolute w-full z-20 top-0`}>
        <img data-aos="fade-down" alt={'cvcorp Icon'} id="mainLogo" src={lightLogo} className="lg:h-28 h-20 sm:hidden block" />
    </div>

export const sharedHeading = (content) => {
    return <h1 className="text-center font-light md:w-2/3 lg:text-8xl text-5xl md:text-6xl customFont text-red-600" style={{ letterSpacing: '0.05em' }}>{content}</h1>
}

export const miniSharedHeading = (content) => {
    return <h2 className="text-center pt-40 font-light flex lg:flex-row flex-col text lg:text-lg" style={{ letterSpacing: '0.5em' }}>{content}</h2>
}