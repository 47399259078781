import { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from '../home/Home';
import About from '../about/About';
import Course from '../course/Course';
import Placements from '../placements/Placements';
import ContactUs from '../contactUs/ContactUs';
import Navbar from './Navbar';
import DropMenu from './DropMenu';
import firebase from '.././../firebase/Firebase';
import AppContext from '../../context/AppContext';
import Temp from '../temp';

const App = () => {

  const appContext = useContext(AppContext);
  const { dispatch } = appContext;

  

  useEffect(() => {
    let doc = firebase.firestore().collection('successStories2');
    let dashboardSubsription = doc.onSnapshot(docSnapshot => {
      let fetchedData = [];
      docSnapshot.docs.forEach(x => fetchedData.push(...x.data().list));
      let temp = [];
      fetchedData.forEach(x => temp.push({
        YOP: x.YOP,
        Salary: x.Salary,
        Branch: x.Branch,
        fullName: x.fullName,
        Placement: x.Placement,
        College: x.College,
        Company: x.Company,
        batch: x.batch
      }))
      dispatch({
        type: 'on_input',
        payload: {
          key: 'dashboard.successStories',
          value: temp
        }
      })
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

    return () => {
      dashboardSubsription();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="bgColor1">
      <Router>
        <div className='sm:block hidden'><Navbar /></div>
        <div className='sm:hidden block'><DropMenu /></div>
        <Switch>
          <Route path="/course">
            <Course />
          </Route>
          <Route path="/placements">
            <Placements />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/talent-test">
            <Temp/>
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Redirect to="/home" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
