import React, { useEffect } from 'react';
import { ChevronDown } from 'react-feather';
import AOS from 'aos';
import "aos/dist/aos.css";

import Footer from '../shared/Footer';
import '../about/about.css';
import './course.css';
import { ImageEle, miniSharedHeading, sharedHeading } from '../shared/SharedElemets';

const Course = () => {

    useEffect(() => {
        AOS.init({
            duration: 600,
            offset: -10,
            once: true,
        });
    }, []);

    return (
        <div className="bgColor1 w-full divCenter flex-col text-center">
            {ImageEle}
            <div data-aos="fade-down" className="w-full z-10 divCenter flex-col px-2">
                {miniSharedHeading("Your Career Partner")}
                {sharedHeading("Course")}
            </div>
            <div data-aos="fade-up" className="text-gray-800 text-center font-light divCenter flex-col py-14">
                <p className="lg:text-4xl text-3xl customFont ">What will you learn?</p>
                <p className="lg:text-2xl text-xl md:w-2/3 mt-10 tracking-wider mx-2">Our industry experienced trainers will:</p>
                <ul className="md:text-2xl text-xl md:w-2/3 mx-4 mt-2 tracking-tight">
                    <li>Help you understand the industry requirements</li>
                    <li>Assess your individual Strengths and Weaknesses</li>
                    <li>Prepare skill gap analysis</li>
                    <li>Polish your approach to ensure that you deliver your very best impression when meeting potential recruiters</li>
                </ul>
            </div>
            <div className="bgColor2 w-full divCenter flex-col px-2 py-10">
                <p data-aos="fade-up" className="text-3xl lg:text-4xl customFont text-red-600">What does the training involve?</p>
                <p data-aos="fade-up" className="mt-10 font-light lg:font-extralight lg:text-2xl text-xl md:w-2/3 tracking-tight">We focus on end to end job Training which involves:</p>
                <ul data-aos="fade-up" className="mt-2 lg:px font-light lg:font-extralight lg:text-2xl text-xl tracking-tight">
                    <li>Java</li>
                    <li>Programming</li>
                    <li>Aptitude</li>
                    <li>Communication</li>
                </ul>
                <p data-aos="fade-up" className="mt-10 font-light lg:font-extralight lg:text-2xl text-xl md:w-2/3 tracking-wide">Once the Student is trained in Core Java, based on the client requirements we train Students on</p>
                <ul data-aos="fade-up" className="mt-2 lg:px font-light lg:font-extralight lg:text-2xl text-xl tracking-tight">
                    <li>.Net</li>
                    <li>Python</li>
                    <li>Javascript</li>
                    <li>React JS</li>
                </ul>
                {/* <p data-aos="fade-up" className="md:text-xl lg:text-2xl md:w-2/3 mt-10 tracking-wider mx-2">(Offline Training)</p>
                <p data-aos="fade-up" className="font-light lg:text-2xl text-xl md:w-2/3 mt-2 mx-2">
                    We are running offline classes. We are conducting Online Classes through Microsoft teams and daily by evening we will be uploading the recorded sessions of the particular day in our mobile app so that students may revise the classes while practicing after classes.
                </p> */}
                <p data-aos="fade-up" className="text-2xl lg:text-3xl mt-10 tracking-wider">Exams through:</p>
                <ul data-aos="fade-up" className="mt-2 lg:px font-light lg:font-extralight lg:text-2xl text-xl tracking-tight">
                    <li><span className="text-red-600">CVCORP App</span> - MCQ Exams</li>
                    <li><span className="text-red-600">InStacks</span> - Online Coding Practice</li>
                </ul>
            </div>
            <div className="bgColor1 w-full py-10 divCenter flex-col px-2">
                <p data-aos="fade-up" className="font-medium lg:text-2xl text-xl md:w-2/3 tracking-wider">Usually the recruiters look for following qualities in an individual</p>
                <div data-aos="fade-up" className="animate-bounce mt-10 text-2xl">
                    <ChevronDown size="34" />
                </div>
                <p data-aos="fade-up" className="text-2xl lg:text-3xl mt-10 text-red-600">Good Aptitude</p>
                <p data-aos="fade-up" className="mt-2 font-light lg:font-extralight lg:text-2xl text-xl md:w-2/3 tracking-tight">Not just formulas and shortcuts, its more about how a fresher can understand the given problem statement and come with the optimal solution</p>
                <p data-aos="fade-up" className="text-2xl lg:text-3xl mt-10 text-red-600">Great Attitude & Communication Skills</p>
                <p data-aos="fade-up" className="mt-2 font-light lg:font-extralight lg:text-2xl text-xl md:w-2/3 tracking-tight">The way the candidate presents himself/ herself in the interview, their ability to communicate with their team members, the right attitude and the willingness to learn, openness to feedback, and positive energy!</p>
                <p data-aos="fade-up" className="text-2xl lg:text-3xl mt-10 text-red-600">Technical Skills</p>
                <ul data-aos="fade-up" className="mt-2 lg:px font-light lg:font-extralight lg:text-2xl text-xl md:w-2/3 tracking-tight">
                    <li>Good Programming skills- Should be able to code, debug and explain the program (not just remember the program).</li>
                    <li>Core Java</li>
                    <li>UI (HTML/CSS) & javascript</li>
                    <li>SQL & Basic Cloud Hosting (Github / AWS / Firebase)</li>
                    <li>Clear knowledge on Final year Project</li>
                    <li>Real time Project</li>
                </ul>
            </div>
            <div className="bgColor2 w-full divCenter flex-col px-2 py-10">
                <p data-aos="fade-up" className="lg:text-4xl text-3xl customFont text-red-600">What is the Course Duration?</p>
                <p data-aos="fade-up" className="font-light lg:text-2xl text-xl md:w-2/3 mt-2 mx-2">
                    It's typically a 6-7 months course to complete all the syllabus with practice.
                </p>
            </div>
            {/* <div className="divCenter text-center w-full text-white" style={{ backgroundColor: '#d0202d' }}>
                <p className="md:text-3xl lg:text-5xl text-2xl md:w-2/3 mx-2 my-10 tracking-widest customFont">Teach Assess <span>ReTeachModel</span></p>
            </div> */}
            <div className="bgColor1 w-full py-10 divCenter flex-col px-2">
                <p data-aos="fade-up" className="lg:text-4xl text-3xl customFont text-red-600">What other Support will I receive from Trainers ?</p>
                <p data-aos="fade-up" className="font-light lg:text-2xl text-xl md:w-2/3 mt-10 mx-2">
                    Our Trainers are available from <span className="text-red-600">9 AM - 10 PM</span> for any doubts and clarifications.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default Course;