import React, { useEffect, useState, useContext, Suspense } from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Cell, Bar, ResponsiveContainer } from 'recharts';

import AppContext from '../../context/AppContext';
import Loading from '../shared/Loading';

const COLORS = ['rgb(0 136 254 / 75%)', 'rgb(0 196 159 / 75%)', 'rgb(118 255 132 / 0.75)', 'rgb(255 128 66 / 75%)'];

const CustomizedLabel = (props) => {
    const { x, y, value, width, height } = props;
    return (
        <text
            x={x + (width / 2)}
            y={y + (height / 2)}
            dy={-4}
            fontSize='16'
            fontFamily='sans-serif'
            fill={'#353536'}
            textAnchor="middle"
        >
            {value}
        </text>
    )
}

const generateYearArray = (initialYear) => {
    var currentYear = new Date().getFullYear();
    var yearArray = [];

    for (var year = initialYear; year <= currentYear; year++) {
        yearArray.push({ name: String(year), key: String(year), count: 0 });
    }

    return yearArray;
}

const YChart = () => {

    const context = useContext(AppContext);
    const { state } = context;

    const [data, setData] = useState([{ name: '2017', key: '2017-19', count: 0 }, { name: '2018', key: '2017-19', count: 0 }, { name: '2019', key: '2017-19', count: 0 }, ...generateYearArray(2020)]);


    useEffect(() => {
        if (state.dashboard.successStories.length !== 0) {
            let filteredArray = [];
            for (let i = 0; i < data.length; i++) {
                let count = 0;
                for (let j = 0; j < state.dashboard.successStories.length; j++) {
                    if (state.dashboard.successStories[j].Placement === data[i].key) {
                        count++;
                    }
                }
                filteredArray.push(count);
            }
            setData([{ name: '2017', key: '2017-19', count: 46 }, { name: '2018', key: '2017-19', count: 102 }, { name: '2019', key: '2017-19', count: 198 }, ...generateYearArray(2020).map((item, index) => { return { name: item.name, key: item.key, count: filteredArray[index + 3] } })])
        }
    }, [state.dashboard.successStories]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='w-full'>
            <div className={`mb-6 w-full text-xl sm:text-2xl text-center tracking-widest font-light`}>Our Placement Records</div>
            <div className="mb-6 text-md"><span className="neoEle text-gray-800 rounded py-1 px-2 border font-semibold">Total Offers: {state.dashboard.successStories.length > 0 ? state.dashboard.successStories.length : 'Loading...'}</span></div>
            {
                state.dashboard.successStories.length > 0 ?
                    <Suspense fallback={<div>Loading .....</div>}>
                        <ResponsiveContainer height={300} width="100%">
                            <BarChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" tick={{ fill: `#0d0d0d` }} />
                                <YAxis dataKey="count" tick={{ fill: `#0d0d0d` }} />
                                <Bar dataKey="count" fill="#32dede" radius={[5, 5, 0, 0]} label={(e) => CustomizedLabel(e)} >
                                    {
                                        data.map((entry, index) => <Cell className="cursor-pointer" key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Suspense>
                    :
                    <Loading />
            }
        </div>
    )
}

export default YChart;