import React, { createContext, useReducer } from 'react';
import set from 'lodash/set';

const initialState = {
    darkMode: true,
    dashboard: {
      successStories: []
    }
}  

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'on_input':
      return set({ ...state }, payload.key, payload.value);
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

const AppContext = createContext(initialState);
const { Provider } = AppContext;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const AppProvider = StateProvider;
export const AppConsumer = AppContext.Consumer;

export default AppContext;
